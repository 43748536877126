<template>
  <div>
    <NavBar
    style="max-height: 50px;margin-bottom: 10px;"
    :username="currentUserName"
    :pageName="pageName"
    ></NavBar>
    <v-app>
      <div style="background-color: #f5faff; height: 93vh !important;">
         <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height=50px;" :pageName="pageName"></SideBar>
        </v-col>
          <v-col cols="10" style="overflow:scroll; height:90vh">
            <div style="margin: 10px 20px 20px 50px">
              <div
              style="
              
              display: flex;
              flex-direction: row;
              justify-content: space-between; 
              vertical-align:middle;
              ">
               <div style="width: 30%" align="left">
              <span style="font-weight: 800;padding-left: 5px; font-size: 12pt;
                text-transform: uppercase;   letter-spacing: 1px;">
                <h3>All Patient Information</h3>
              </span></div>
              <span>
                <div style="display: flex;">
                  <v-select
                  v-model="search_type"
                  :items="items"
                  placeholder="Search With"
                  solo
                  height = "10%"
                  dense
                  style="width: 30%; margin-right: 5px;z-index: 8; margin-top:18px"
                  @change="nrc_number='', phone_number=''"
                  ></v-select>
                  <div style="margin-left: 2%">
                    <v-text-field
                    v-if="search_type == 'NRC'"
                    v-model="nrc_number"
                    label="Enter NRC number"
                    dense
                    solo
                    style="width: 90%"
                    >
                    <template v-slot:append>
                     <v-btn  @click="filterPatient" icon>
                      <img
                      style="width: 25px; height:25px;"
                      src="https://s3iconimages.mymedicine.com.mm/search.svg"
                      />
                    </v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                v-if="search_type == 'Phone Number'"
                v-model="phone_number"
                placeholder="Type here"
                label="Enter Phone number"
                dense
                solo
                style="width: 90%"
                ><template v-slot:append>
                 <v-btn @click="filterPatient" icon>
                  <img
                  style="width: 25px; height:25px;"
                  src="https://s3iconimages.mymedicine.com.mm/search.svg"
                  />
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field
            v-else-if="search_type != ''"
            v-show="false"
            ></v-text-field>
          </div>
        </div>
      </span>
    </div>
    <div style="margin-top: 1%; overflow: scroll; overflow-x: hidden; height: 45px 0px" v-if="!isLoading">

      <table class="tableClass" id="data"   v-if="patient_list.length > 0">
        <thead>
          <tr>
            <th class="text-left" >Patient Name</th>
            <th class="text-left">Phone number</th>
            <th class="text-left">Gender</th>
            <th class="text-left">Date of Birth</th>
            <th class="text-left">NRC</th>
            <th class="text-left">KBZ Employee</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
          v-for="patient in patient_list"
          :key="patient.phoneNumber"
          >
          <td   align="left">
            {{ patient.customer_name }}
          </td>
          <td align="left">
            {{ patient.phoneNumber }}
          </td>
          <td align="left">
            {{ patient.customer_gender }}
          </td>
          <td align="left">
            {{ patient.customer_dob != null ? patient.customer_dob.split("T")[0] : "NA"  }}
          </td>
          <td align="left">
            {{ patient.NRC }}
          </td>
          <td align="left">
            {{patient.kbzEmployee == true ? "Yes" : "No"}}
          </td>
          <td align="left">
            <!-- commented for future reference -->
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a style="width: 100%" icon @click="changeNumber(patient._id)" depressed v-on="on" class="editAppointment">
                  <img src="https://s3iconimages.mymedicine.com.mm/Mobile_Number.svg" style="height: 24px; width: 24px;" />
                </a>
              </template>
              <span>Edit Mobile Number</span>
            </v-tooltip> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a style="width: 100%" icon @click="viewPatient(patient._id)" depressed v-on="on" class="editAppointment">
                  <img src="https://s3iconimages.mymedicine.com.mm/edit.svg" style="height: 24px; width: 24px;" />
                </a>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </td>
          <!-- .split("T")[0] -->
        </tr>
      </tbody>
    </table>
    <div v-else>
    Sorry no matching results found
    </div>
    </div>
    <div v-else>
      <v-progress-circular
      indeterminate
      color="primary"
      ></v-progress-circular>
    </div>

 <!-- <v-progress-circular
    indeterminate
    color="primary"
    v-else
    ></v-progress-circular> -->
  <div v-if="!isLoading && pagination_list > 1">
     <v-pagination
   circle
      v-model="page"
      :length="pagination_list"
      :total-visible="7"
      @input="findPage(page)"
    ></v-pagination>
  <!-- <div
  id="page-navigation"
  v-for="(pagination, index) in pagination_list"
  :key="pagination.limit"
  style="
  display: flex;
  flex-dirextion: row;
  justify-content: center;
  display: inline-block;
  margin-top:2%
  "
  >
  <span
  style="
  display: flex;
  flex-dirextion: row;
  justify-content: center;
  color: black
  "
  >
  <v-btn
  style="margin: 3px 1px; font-weight: 600"
  color="white"
  class="primary"
  elevation="2"
  fab
  small
  @click="findPage(index + 1)"
  >
  {{ index + 1 }}
</v-btn>
</span>
</div> -->
</div>
</div>
</v-col>
</v-row>
</div>
</v-app>
</div>
</template>

<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: "allPatient",

  data() {
    return {
      patient_list: "",
      page:1,
      particular_list: "",
      user_id: "",
      currentUser: "",
      search_type: "",
      currentUserName: "",
      limit: "",
      pageName: "",
      nrc_number: "",
      phone_number: "",
      pagination_list: 0,
      isLoading : false,
      items: ["Phone Number", "NRC"],
      current_nrc: "",
      current_phone_number: ""
    };
  },
  mounted() {
    this.pageName = "ViewAllPatient";
    this.currentUser = this.$cookies.get("token");
    this.isLoading = true
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        page : 1,
        limit : 10
      }
      axios_auth_instance_admin.post("/adminViewAllPatients", data)
      .then((successResponse) => {
         this.currentUserName = successResponse.data.admin_data.admin_name;
        this.patient_list = successResponse.data.data.docs;
          this.pagination_list = successResponse.data.data.pages;
          this.current_nrc = this.nrc_number;
          this.current_phone_number = this.phone_number;
        this.isLoading = false;
      })
      .catch((errorResponse) => {
        this.isLoading = false;
        handleError(errorResponse, this.$router, 'adminViewAllPatients', 'AdminLogin', 'token');
      });
    }
  },
  components: {
    NavBar,SideBar
      },
  methods: {
    changeNumber(patient_id)
    {
      this.$router.push({
        path: "/admin/changePatientNumber/" + patient_id
      });
    },
    viewPatient(patient_id) {
      this.$router.push({
        path: "/admin/updateUserDetails/" + patient_id
      });
    },
    gotoLoginPage() {
      var deleteData = {
        idToken: this.currentUser,
      };
      axios
      .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
      .then((deleteResponse) => {
        this.$cookies.remove("token");
      })
      .catch((error) => {
      });
      localStorage.clear();
      this.$router.push({
        name: "AdminLogin",
      });
    },
    editDoctor(doctor_id) {
      this.$router.push({
        path: "editdoctor/" + doctor_id,
      });
    },
    filterPatient() {
      this.isLoading = true;
      if(this.nrc_number != '' || this.phoneNumber != ''){
      var getPatientData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        page: 1,
        limit: 10,
        // NRC: this.nrc_number,
        // phoneNumber: this.phone_number,
      };
      if(this.search_type == "NRC")
      {
        getPatientData.NRC = this.nrc_number;
        this.current_nrc = this.nrc_number;
      }
      else if(this.search_type == "Phone Number")
      {
        getPatientData.phoneNumber = this.phone_number;
        this.current_phone_number = this.phone_number;
      }
      axios
      .post(
        process.env.VUE_APP_BACKEND_URL + "/readAllCustomers",
        getPatientData
        )
      .then((getparticularListResponse) => {
        this.isLoading = false;
        this.patient_list = getparticularListResponse.data.data;
       })
      .catch((getParticularListError) => {
        this.isLoading = false
        if (getParticularListError.response.status == 401) {
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name: "AdminLogin",
          });
        } else if(getParticularListError.response.status == 400){
          window.alert("Something went wrong with the database...");
        }else {
          window.alert("Something went wrong...");
        }
      });}
      else{
        window.alert("Search Field Can't be empty")
      }
    },
    findPage(pageNum) {
       this.isLoading = true;
      var getPageData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        page: pageNum,
        limit: 10,
        NRC: this.current_phone_number,
        phoneNumber: this.current_nrc,
      };
      axios
      .post(
        process.env.VUE_APP_BACKEND_URL + "/readAllCustomers",
        getPageData
        )
      .then((getPatientListResponse) => {
        this.isLoading = false;
        this.patient_list = getPatientListResponse.data.data.docs;
        this.pagination_list = getPatientListResponse.data.data.totalPages;
        })
      .catch((getPatientListError) => {
         this.isLoading = false;
        if (getPatientListError.response.status == 401) {
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name: "AdminLogin",
          });
        } else if(getPatientListError.response.status == 400)
        {
          window.alert("Something went wrong with the database...");
        }
        else
        {
          window.alert("Something went wrong...");
        }
      });
    },
  },
};
</script>

<style scoped>
th {
  border: 0px;
}
td {
  padding-left: 8px;
  padding:5px;
  font-size: 8pt;
  font-weight: 700;
   height: 14px;
}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 40px;
  padding-left: 5px;
}
tr {
  border-bottom: #d4efff 1px solid !important;
   height: 15px;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
  /* margin-left: 10px; */
  /* height: 30%; */
}
table > tr > td:last-child {
  white-space: nowrap;
}
.btnClass {
  background-color: transparent;
}
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#facebook{
  display: none;
}
.editAppointment
{
  padding:  0px 5px 0px 5px;
}
</style>